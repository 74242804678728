import {
  DataConnectionType,
  DockerImageRepositoryType,
  ExternalFileIntegrationType,
} from "@hex/common";

import AlloyDBIconFull from "../../static/connection/AlloyDB-Full.inline.svg";
import AlloyDBIconFullInverted from "../../static/connection/AlloyDB-FullInverted.inline.svg";
import AlloyDBIconMinimal from "../../static/connection/AlloyDB-Minimal.inline.svg";
import AmazonECRIconFull from "../../static/connection/AmazonECR-Full.inline.svg";
import AmazonECRIconFullInverted from "../../static/connection/AmazonECR-FullInverted.inline.svg";
import AmazonECRIconMinimal from "../../static/connection/AmazonECR-Minimal.inline.svg";
import AmazonS3IconFull from "../../static/connection/AmazonS3-Full.inline.svg";
import AmazonS3IconFullInverted from "../../static/connection/AmazonS3-FullInverted.inline.svg";
import AmazonS3IconMinimal from "../../static/connection/AmazonS3-Minimal.inline.svg";
import AthenaIconFull from "../../static/connection/Athena-Full.inline.svg";
import AthenaIconFullInverted from "../../static/connection/Athena-FullInverted.inline.svg";
import AthenaIconMinimal from "../../static/connection/Athena-Minimal.inline.svg";
import AthenaIconMinimalInverted from "../../static/connection/Athena-MinimalInverted.inline.svg";
import BigQueryIconFull from "../../static/connection/BigQuery-Full.inline.svg";
import BigQueryIconFullInverted from "../../static/connection/BigQuery-FullInverted.inline.svg";
import BigQueryIconMinimal from "../../static/connection/BigQuery-Minimal.inline.svg";
import BigQueryIconMinimalInverted from "../../static/connection/BigQuery-MinimalInverted.inline.svg";
import ClickhouseIconFull from "../../static/connection/Clickhouse-Full.inline.svg";
import ClickhouseIconFullInverted from "../../static/connection/Clickhouse-FullInverted.inline.svg";
import ClickhouseIconMinimal from "../../static/connection/Clickhouse-Minimal.inline.svg";
import CloudSqlIconFull from "../../static/connection/CloudSQL-Full.inline.svg";
import CloudSqlIconFullInverted from "../../static/connection/CloudSQL-FullInverted.inline.svg";
import CloudSqlIconMinimal from "../../static/connection/CloudSQL-Minimal.inline.svg";
import DatabricksIconFull from "../../static/connection/Databricks-Full.inline.svg";
import DatabricksIconFullInverted from "../../static/connection/Databricks-FullInverted.inline.svg";
import DatabricksIconMinimal from "../../static/connection/Databricks-Minimal.inline.svg";
import DatabricksIconMinimalInverted from "../../static/connection/Databricks-MinimalInverted.inline.svg";
import DB2IconFull from "../../static/connection/DB2-Full.inline.svg";
import DB2IconFullInverted from "../../static/connection/DB2-FullInverted.inline.svg";
import DB2IconMinimal from "../../static/connection/DB2-Minimal.inline.svg";
import DB2IconMinimalInverted from "../../static/connection/DB2-MinimalInverted.inline.svg";
import DockerHubIconFull from "../../static/connection/DockerHub-Full.inline.svg";
import DockerHubIconFullInverted from "../../static/connection/DockerHub-FullInverted.inline.svg";
import DockerHubIconMinimal from "../../static/connection/DockerHub-Minimal.inline.svg";
import DockerHubIconMinimalInverted from "../../static/connection/DockerHub-MinimalInverted.inline.svg";
import DremioIconFull from "../../static/connection/Dremio-Full.inline.svg";
import DremioIconFullInverted from "../../static/connection/Dremio-FullInverted.inline.svg";
import DremioIconMinimal from "../../static/connection/Dremio-Minimal.inline.svg";
import DremioIconMinimalInverted from "../../static/connection/Dremio-MinimalInverted.inline.svg";
import GoogleArtifactRegistryFull from "../../static/connection/GoogleArtifactRegistry-Full.inline.svg";
import GoogleArtifactRegistryFullInverted from "../../static/connection/GoogleArtifactRegistry-FullInverted.inline.svg";
import GoogleArtifactRegistryMinimal from "../../static/connection/GoogleArtifactRegistry-Minimal.inline.svg";
import GoogleDriveIconFull from "../../static/connection/GoogleDrive-Full.inline.svg";
import GoogleDriveIconFullInverted from "../../static/connection/GoogleDrive-FullInverted.inline.svg";
import GoogleDriveIconMinimal from "../../static/connection/GoogleDrive-Minimal.inline.svg";
import MariaDBIconFull from "../../static/connection/MariaDB-Full.inline.svg";
import MariaDBIconFullInverted from "../../static/connection/MariaDB-FullInverted.inline.svg";
import MariaDBIconMinimal from "../../static/connection/MariaDB-Minimal.inline.svg";
import MariaDBIconMinimalInverted from "../../static/connection/MariaDB-MinimalInverted.inline.svg";
import MaterializeIconFull from "../../static/connection/Materialize-Full.inline.svg";
import MaterializeIconFullInverted from "../../static/connection/Materialize-FullInverted.inline.svg";
import MaterializeIconMinimal from "../../static/connection/Materialize-Minimal.inline.svg";
import MaterializeIconMinimalInverted from "../../static/connection/Materialize-MinimalInverted.inline.svg";
import MotherDuckIconFull from "../../static/connection/MotherDuck-Full.inline.svg";
import MotherDuckIconFullInverted from "../../static/connection/MotherDuck-FullInverted.inline.svg";
import MotherDuckIconMinimal from "../../static/connection/MotherDuck-Minimal.inline.svg";
import MotherDuckIconMinimalInverted from "../../static/connection/MotherDuck-MinimalInverted.inline.svg";
import MySQLIconFull from "../../static/connection/MySQL-Full.inline.svg";
import MySQLIconFullInverted from "../../static/connection/MySQL-FullInverted.inline.svg";
import MySQLIconMinimal from "../../static/connection/MySQL-Minimal.inline.svg";
import MySQLIconMinimalInverted from "../../static/connection/MySQL-MinimalInverted.inline.svg";
import PostgresIconFull from "../../static/connection/Postgres-Full.inline.svg";
import PostgresIconFullInverted from "../../static/connection/Postgres-FullInverted.inline.svg";
import PostgresIconMinimal from "../../static/connection/Postgres-Minimal.inline.svg";
import PostgresIconMinimalInverted from "../../static/connection/Postgres-MinimalInverted.inline.svg";
import PrestoIconFull from "../../static/connection/Presto-Full.inline.svg";
import PrestoIconFullInverted from "../../static/connection/Presto-FullInverted.inline.svg";
import PrestoIconMinimal from "../../static/connection/Presto-Minimal.inline.svg";
import PrestoIconMinimalInverted from "../../static/connection/Presto-MinimalInverted.inline.svg";
import RedshiftIconFull from "../../static/connection/Redshift-Full.inline.svg";
import RedshiftIconFullInverted from "../../static/connection/Redshift-FullInverted.inline.svg";
import RedshiftIconMinimal from "../../static/connection/Redshift-Minimal.inline.svg";
import RedshiftIconMinimalInverted from "../../static/connection/Redshift-MinimalInverted.inline.svg";
import SnowflakeIconFull from "../../static/connection/Snowflake-Full.inline.svg";
import SnowflakeIconFullInverted from "../../static/connection/Snowflake-FullInverted.inline.svg";
import SnowflakeIconMinimal from "../../static/connection/Snowflake-Minimal.inline.svg";
import SnowflakeIconMinimalInverted from "../../static/connection/Snowflake-MinimalInverted.inline.svg";
import SparkIconFull from "../../static/connection/Spark-Full.inline.svg";
import SparkIconMinimal from "../../static/connection/Spark-Minimal.inline.svg";
import SqlserverIconFull from "../../static/connection/SQLServer-Full.inline.svg";
import SqlserverIconFullInverted from "../../static/connection/SQLServer-FullInverted.inline.svg";
import SqlserverIconMinimal from "../../static/connection/SQLServer-Minimal.inline.svg";
import SqlserverIconMinimalInverted from "../../static/connection/SQLServer-MinimalInverted.inline.svg";
import StarburstIconFull from "../../static/connection/Starburst-Full.inline.svg";
import StarburstIconFullInverted from "../../static/connection/Starburst-FullInverted.inline.svg";
import StarburstIconMinimal from "../../static/connection/Starburst-Minimal.inline.svg";
import StarburstIconMinimalInverted from "../../static/connection/Starburst-MinimalInverted.inline.svg";
import TransformIconFull from "../../static/connection/Transform-Full.inline.svg";
import TransformIconFullInverted from "../../static/connection/Transform-FullInverted.inline.svg";
import TransformIconMinimal from "../../static/connection/Transform-Minimal.inline.svg";
import TransformIconMinimalInverted from "../../static/connection/Transform-MinimalInverted.inline.svg";
import TrinoIconFull from "../../static/connection/Trino-Full.inline.svg";
import TrinoIconFullInverted from "../../static/connection/Trino-FullInverted.inline.svg";
import TrinoIconMinimal from "../../static/connection/Trino-Minimal.inline.svg";
import TrinoIconMinimalInverted from "../../static/connection/Trino-MinimalInverted.inline.svg";
import { SVG } from "../../SVG.js";

export interface IconSet {
  minimal: SVG;
  full: SVG;
  fullInverted?: SVG;
  minimalInverted?: SVG;
}

export const DataConnectionIcons: Record<
  DataConnectionType | ExternalFileIntegrationType | DockerImageRepositoryType,
  IconSet | undefined
> = {
  athena: {
    full: AthenaIconFull,
    minimal: AthenaIconMinimal,
    fullInverted: AthenaIconFullInverted,
    minimalInverted: AthenaIconMinimalInverted,
  },
  bigquery: {
    full: BigQueryIconFull,
    minimal: BigQueryIconMinimal,
    fullInverted: BigQueryIconFullInverted,
    minimalInverted: BigQueryIconMinimalInverted,
  },
  cloudsql: {
    full: CloudSqlIconFull,
    minimal: CloudSqlIconMinimal,
    fullInverted: CloudSqlIconFullInverted,
    minimalInverted: CloudSqlIconMinimal,
  },
  cloudsql__postgres: {
    full: CloudSqlIconFull,
    minimal: CloudSqlIconMinimal,
    fullInverted: CloudSqlIconFullInverted,
    minimalInverted: CloudSqlIconMinimal,
  },
  cloudsql__mysql: {
    full: CloudSqlIconFull,
    minimal: CloudSqlIconMinimal,
    fullInverted: CloudSqlIconFullInverted,
    minimalInverted: CloudSqlIconMinimal,
  },
  cloudsql__sqlserver: {
    full: CloudSqlIconFull,
    minimal: CloudSqlIconMinimal,
    fullInverted: CloudSqlIconFullInverted,
    minimalInverted: CloudSqlIconMinimal,
  },
  dockerhub: {
    full: DockerHubIconFull,
    minimal: DockerHubIconMinimal,
    fullInverted: DockerHubIconFullInverted,
    minimalInverted: DockerHubIconMinimalInverted,
  },
  ecr: {
    full: AmazonECRIconFull,
    minimal: AmazonECRIconMinimal,
    fullInverted: AmazonECRIconFullInverted,
    minimalInverted: AmazonECRIconMinimal,
  },
  "google-artifact-registry": {
    full: GoogleArtifactRegistryFull,
    fullInverted: GoogleArtifactRegistryFullInverted,
    minimal: GoogleArtifactRegistryMinimal,
  },
  mysql: {
    full: MySQLIconFull,
    minimal: MySQLIconMinimal,
    fullInverted: MySQLIconFullInverted,
    minimalInverted: MySQLIconMinimalInverted,
  },
  sqlserver: {
    full: SqlserverIconFull,
    minimal: SqlserverIconMinimal,
    fullInverted: SqlserverIconFullInverted,
    minimalInverted: SqlserverIconMinimalInverted,
  },
  mariadb: {
    full: MariaDBIconFull,
    minimal: MariaDBIconMinimal,
    fullInverted: MariaDBIconFullInverted,
    minimalInverted: MariaDBIconMinimalInverted,
  },
  postgres: {
    full: PostgresIconFull,
    minimal: PostgresIconMinimal,
    fullInverted: PostgresIconFullInverted,
    minimalInverted: PostgresIconMinimalInverted,
  },
  prestodb: {
    full: PrestoIconFull,
    minimal: PrestoIconMinimal,
    fullInverted: PrestoIconFullInverted,
    minimalInverted: PrestoIconMinimalInverted,
  },
  redshift: {
    full: RedshiftIconFull,
    minimal: RedshiftIconMinimal,
    fullInverted: RedshiftIconFullInverted,
    minimalInverted: RedshiftIconMinimalInverted,
  },
  snowflake: {
    full: SnowflakeIconFull,
    minimal: SnowflakeIconMinimal,
    fullInverted: SnowflakeIconFullInverted,
    minimalInverted: SnowflakeIconMinimalInverted,
  },
  spark: {
    full: SparkIconFull,
    minimal: SparkIconMinimal,
  },
  transform: {
    full: TransformIconFull,
    minimal: TransformIconMinimal,
    fullInverted: TransformIconFullInverted,
    minimalInverted: TransformIconMinimalInverted,
  },
  trino: {
    full: TrinoIconFull,
    minimal: TrinoIconMinimal,
    fullInverted: TrinoIconFullInverted,
    minimalInverted: TrinoIconMinimalInverted,
  },
  dremio: {
    full: DremioIconFull,
    minimal: DremioIconMinimal,
    fullInverted: DremioIconFullInverted,
    minimalInverted: DremioIconMinimalInverted,
  },
  databricks: {
    full: DatabricksIconFull,
    minimal: DatabricksIconMinimal,
    fullInverted: DatabricksIconFullInverted,
    minimalInverted: DatabricksIconMinimalInverted,
  },
  clickhouse: {
    full: ClickhouseIconFull,
    minimal: ClickhouseIconMinimal,
    fullInverted: ClickhouseIconFullInverted,
    minimalInverted: ClickhouseIconMinimal,
  },
  alloydb: {
    full: AlloyDBIconFull,
    minimal: AlloyDBIconMinimal,
    fullInverted: AlloyDBIconFullInverted,
    minimalInverted: AlloyDBIconMinimal,
  },
  starburst: {
    full: StarburstIconFull,
    minimal: StarburstIconMinimal,
    fullInverted: StarburstIconFullInverted,
    minimalInverted: StarburstIconMinimalInverted,
  },
  materialize: {
    full: MaterializeIconFull,
    minimal: MaterializeIconMinimal,
    fullInverted: MaterializeIconFullInverted,
    minimalInverted: MaterializeIconMinimalInverted,
  },
  motherduck: {
    full: MotherDuckIconFull,
    minimal: MotherDuckIconMinimal,
    fullInverted: MotherDuckIconFullInverted,
    minimalInverted: MotherDuckIconMinimalInverted,
  },
  db2: {
    full: DB2IconFull,
    minimal: DB2IconMinimal,
    fullInverted: DB2IconFullInverted,
    minimalInverted: DB2IconMinimalInverted,
  },

  // external file integrations
  s3: {
    full: AmazonS3IconFull,
    minimal: AmazonS3IconMinimal,
    fullInverted: AmazonS3IconFullInverted,
    minimalInverted: AmazonS3IconMinimal,
  },
  googledrive: {
    full: GoogleDriveIconFull,
    minimal: GoogleDriveIconMinimal,
    fullInverted: GoogleDriveIconFullInverted,
    minimalInverted: GoogleDriveIconMinimal,
  },
};
